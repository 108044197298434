import { getTimeZoneDateTime } from '@src/DateTime/helpers'
import { useWonders } from '@src/Fetch/helpers'
import dayjs from 'dayjs'
import { parseAsString, useQueryState } from 'nuqs'

export const BUSINESS_CUTOFF = '04:00:00'

export const useDateQueryState = () => {
  const { data: restaurant } = useWonders(['restaurant_info'])

  const restoLocalDateTime = getTimeZoneDateTime({
    timestamp: null,
    timeZone: restaurant?.location.time_zone ?? undefined,
  })

  const [restoLocalDate, restoLocalTime] = restoLocalDateTime.split(', ')

  const [date, setDate] = useQueryState(
    'date',
    parseAsString
      .withOptions({ shallow: false })
      .withDefault(
        restoLocalTime >= BUSINESS_CUTOFF
          ? dayjs(restoLocalDate).format('YYYY-MM-DD')
          : dayjs(restoLocalDate).subtract(1, 'day').format('YYYY-MM-DD'),
      ),
  )

  return { date, setDate }
}
