import { CircularProgress } from '@mui/material'
import { colors, constants, spacing } from '@src/Styles'
import { useTypographyContext } from '@src/Typography'
import React from 'react'
import { CSSObject, Interpolation, Theme } from '@emotion/react'

const Button = ({
  type = 'button',
  isDisabled,
  isActive,
  onClick,
  isSubmitting,
  variant = 'primary',
  size = 'default',
  cssProps,
  children,
}: {
  type?: 'button' | 'submit' | 'reset' | undefined
  isDisabled?: boolean
  isActive?: boolean
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void
  isSubmitting?: boolean
  variant?: 'primary' | 'blue' | 'gray'
  size?: 'default' | 'small'
  cssProps?: CSSObject
  children: React.ReactNode
}) => {
  const { typography } = useTypographyContext()

  return (
    <button
      type={type}
      disabled={isDisabled}
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingInline: spacing.normal,
        paddingBlock: size === 'default' ? spacing.normal : spacing.base,
        borderRadius: constants.borderRadius.medium,
        cursor: 'pointer',
        ...getVariant({ isActive, variant }),
        ...typography.button.large,
        ...cssProps,
      }}
      onClick={onClick}
    >
      {isSubmitting ? (
        <CircularProgress
          sx={{ color: colors.neutrals[500] }}
          size={typography.button.large.lineHeight}
        />
      ) : (
        children
      )}
    </button>
  )
}

export default Button

const getVariant = ({
  isActive,
  variant,
}: {
  variant: 'primary' | 'blue' | 'gray'
  isActive?: boolean
}) => {
  const variants = {
    primary: {
      background: colors.white[100],
      border: `1px solid ${colors.primary2[500]}`,
      ':disabled': {
        background: colors.neutrals[25],
        borderColor: colors.white[600],
        color: colors.neutrals[100],
        cursor: 'not-allowed',
      },
      ':hover:enabled': {
        background: colors.primary2[50],
      },
      ':active:enabled': {
        color: colors.neutrals[50],
        background: colors.primary2[500],
      },
      ...(isActive != null && isActive === true
        ? {
            background: colors.primary2[50],
            borderColor: colors.primary2[500],
            cursor: 'pointer',
          }
        : {}),
      color: colors.primary2[500],
    },
    blue: {
      background: colors.primary2[500],
      border: `1px solid ${colors.primary2[500]}`,
      ':disabled': {
        background: colors.neutrals[25],
        borderColor: colors.neutrals[25],
        color: colors.neutrals[200],
        cursor: 'not-allowed',
      },
      ':hover:enabled': {
        background: colors.primary2[300],
      },
      ':active:enabled': {
        color: colors.neutrals[50],
        background: colors.primary2[800],
        borderColor: colors.primary2[800],
      },
      ...(isActive != null && isActive === true
        ? {
            background: colors.primary2[300],
            borderColor: colors.primary2[500],
            cursor: 'pointer',
          }
        : {}),
      color: colors.white[500],
    },
    gray: {
      background: colors.white[500],
      border: `1px solid ${colors.neutrals[50]}`,
      ':disabled': {
        background: colors.neutrals[25],
        borderColor: colors.neutrals[25],
        color: colors.neutrals[200],
        cursor: 'not-allowed',
      },
      ':hover:enabled': {
        background: colors.primary2[50],
        borderColor: colors.primary2[100],
      },
      ':active:enabled': {
        color: colors.white[50],
        background: colors.primary2[700],
        borderColor: colors.primary2[700],
      },
      ...(isActive != null && isActive === true
        ? {
            background: colors.primary2[50],
            borderColor: colors.neutrals[50],
            cursor: 'pointer',
          }
        : {}),
      color: colors.neutrals[500],
    },
  }

  return variants[variant]
}
